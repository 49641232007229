import * as React from "react"
import Checkbox from "../../components/Checkbox";
import BorderWithText from "../../components/BorderWithText";
import {FilterGroups, FilterTimes} from "./FilterType";

interface Props {
    myGroupNr?: number
    filterUpdated: (timeFilter: FilterTimes, groupFilter: FilterGroups) => void
    timeFilter: FilterTimes
    groupFilter: FilterGroups
}

interface State {
    timeFilter: FilterTimes
    groupFilter: FilterGroups
}

export default class ScheduleFilter extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            timeFilter: this.props.timeFilter,
            groupFilter: this.props.groupFilter
        };
        this.allGroups = this.allGroups.bind(this);
        this.myGroup = this.myGroup.bind(this);
        this.allTimes = this.allTimes.bind(this);
        this.futureTimes = this.futureTimes.bind(this);
    }

    render() {
        const {timeFilter, groupFilter} = this.state;
        const {myGroupNr} = this.props;
        return (
            <div style={{display: "flex", fontSize: "1rem"}}>
                <BorderWithText text={"Tider"}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Checkbox text="Alla" checked={timeFilter === FilterTimes.ALL_TIMES} onChange={this.allTimes}
                                  id={"allTimes"}/>
                        <Checkbox text="Framtida" checked={timeFilter === FilterTimes.FUTURE_TIMES} onChange={this.futureTimes}
                                  id={"futureTimes"}/>
                    </div>
                </BorderWithText>
                {myGroupNr &&
                <BorderWithText text={"Grupper"}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Checkbox text="Min" checked={groupFilter === FilterGroups.MY_GROUP} onChange={this.myGroup}
                                  id={"myGroup"}/>
                        <Checkbox text="Alla" checked={groupFilter === FilterGroups.ALL_GROUPS} onChange={this.allGroups}
                                  id={"allGroups"}/>
                    </div>
                </BorderWithText>}
            </div>
        );
    }

    allTimes(checked: boolean) {
        const {groupFilter} = this.state;
        if (checked) {
            this.setState({timeFilter: FilterTimes.ALL_TIMES});
            this.props.filterUpdated(FilterTimes.ALL_TIMES, groupFilter);
        } else {
            this.setState({timeFilter: FilterTimes.FUTURE_TIMES});
            this.props.filterUpdated(FilterTimes.FUTURE_TIMES, groupFilter);
        }
    }

    futureTimes(checked: boolean) {
        const {groupFilter} = this.state;
        if (checked) {
            this.setState({timeFilter: FilterTimes.FUTURE_TIMES});
            this.props.filterUpdated(FilterTimes.FUTURE_TIMES, groupFilter);
        } else {
            this.setState({timeFilter: FilterTimes.ALL_TIMES});
            this.props.filterUpdated(FilterTimes.ALL_TIMES, groupFilter);
        }
    }

    myGroup(checked: boolean) {
        const {timeFilter} = this.state;
        if (checked) {
            this.setState({groupFilter: FilterGroups.MY_GROUP});
            this.props.filterUpdated(timeFilter, FilterGroups.MY_GROUP);
        } else {
            this.setState({groupFilter: FilterGroups.ALL_GROUPS});
            this.props.filterUpdated(timeFilter, FilterGroups.ALL_GROUPS);
        }
    }

    allGroups(checked: boolean) {
        const {timeFilter} = this.state;
        if (checked) {
            this.setState({groupFilter: FilterGroups.ALL_GROUPS});
            this.props.filterUpdated(timeFilter, FilterGroups.ALL_GROUPS);
        } else {
            this.setState({groupFilter: FilterGroups.MY_GROUP});
            this.props.filterUpdated(timeFilter, FilterGroups.MY_GROUP);
        }
    }
};
import * as React from "react"
import {mobile} from "../App"

interface Props {
    onChange: (checked: boolean) => void
    checked?: boolean
    disabled?: boolean
    id: string
    text?: string
}

export default class Checkbox extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.checked !== prevProps.checked) {
            this.setState({checked: !!this.props.checked});
        }
    }

    render() {
        const length = mobile ? "4rem" : "1.5rem";
        const svgLeft = mobile ? "0.4rem" : "0.2rem";
        const borderRadius = mobile ? "0.3rem" : "0.1rem";
        const fontSize = mobile ? "3rem" : "1rem";
        const checked = this.props.checked;
        const backColor = this.props.disabled ? "var(--buttonDisabled)" : "var(--buttonBg)"
        return (
            <div style={{display:"flex", margin: "0.5rem"}}>
                <div style={{
                    flexShrink: 0, display: "inline-block", backgroundColor: backColor, height: length, width: length,
                    borderRadius: borderRadius, fontSize: fontSize, position: "relative"
                }}
                     onClick={this.onClick}>
                    {checked && <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="1.1em" height="1.1em"
                                     style={{position: "absolute", top: "0.2rem", left: svgLeft}}
                                     viewBox="0 0 128.411 128.411">
                        <g>
                            <polygon
                                points="127.526,15.294 45.665,78.216 0.863,42.861 0,59.255 44.479,113.117 128.411,31.666 		"/>
                        </g>
                    </svg>}
                </div>
                {this.props.text &&
                    <div style={{paddingTop: mobile ? "1rem" : "0.2rem", marginLeft: mobile ? "0.5rem" : "0.3rem"}}>{this.props.text}</div>}
            </div>
        );
    }

    private onClick = () => {
        if (!this.props.disabled) {
            this.props.onChange(!this.props.checked);
        }
    }
}
import * as React from "react"
import {KeyboardEvent} from "react"
import {Redirect, RouteComponentProps} from "react-router-dom"
import {forgotPassword} from "../../api/api"
import BasePage, {BasePageProps} from "../BasePage"
import EmailInput from "../../components/EmailInput"
import FullPageMessage from "../../components/FullPageMessage"
import {ErrorMessage} from "../../components/ErrorMessage"
import {mobile} from "../../App"
import {LinkButton} from "../../components/LinkButton"
import {ErrorType} from "../../api/dtos"

interface ForgotPasswordPageProps {
    redirectTo: string
}

interface State {
    email: string,
    errorMessage?: string
    redirectTo?: string
    fatalError: boolean
    emailCompValid: boolean
    emailBackendInvalid: boolean
    showItsDone: boolean
}

type Props = BasePageProps & ForgotPasswordPageProps & RouteComponentProps<{ groupplayId: string }>

class ForgotPasswordPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            fatalError: false,
            emailCompValid: true,
            emailBackendInvalid: false,
            showItsDone: false
        };
        this.handleSendClick = this.handleSendClick.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleKeyClick = this.handleKeyClick.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    handleEmailChange(currentValue: string, valid: boolean) {
        this.setState({email: currentValue, emailCompValid: valid, emailBackendInvalid: false, errorMessage: ''});
    }

    render() {
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        return (<BasePage {...this.props} fatalError={this.state.fatalError}>
                {this.state.showItsDone ? this.renderItsDone() : this.renderForm()}
            </BasePage>
        );
    }


    renderForm() {
        const fontSize = mobile ? "3rem" : "1rem";
        const textMargin = mobile ? "1.5rem" : "0.5rem";
        return (
            <div style={{margin: "auto", display: "flex", flexDirection: "column"}}>
                <EmailInput onChange={this.handleEmailChange} emptyIsValid={false}
                            invalid={this.state.emailBackendInvalid} onKeyDown={this.handleKeyClick}
                            autoFocus={true}/>
                <ErrorMessage text={this.state.errorMessage}/>
                <button onClick={this.handleSendClick}
                        disabled={(!this.state.emailCompValid || this.state.emailBackendInvalid)}>
                    Återställ lösenord
                </button>
                <LinkButton style={{fontSize: fontSize, margin: textMargin, textAlign: "right"}} onClick={this.goBack}/>
            </div>
        );
    }

    renderItsDone() {
        return (
            <FullPageMessage text="Du kommer inom kort få ett mail med en länk för att välja ett nytt lösenord."/>
        );
    }

    async handleSendClick() {
        this.setState({errorMessage: ''});
        try {
            await forgotPassword({email: this.state.email});
            this.setState({ showItsDone: true });
        } catch (error:any) {
            switch (error.type) {
                case ErrorType.INVALID_EMAIL:
                    this.setState({errorMessage: "Felaktig E-mail address", emailBackendInvalid: true});
                    break;
                case ErrorType.EMAIL_NOT_FOUND:
                    this.setState({
                        errorMessage: "Inget konto med den E-mail adressen hittades",
                        emailBackendInvalid: true
                    });
                    break;
                case ErrorType.EMAIL_VERIFICATION_NEEDED:
                    this.setState({errorMessage: "Kontots E-mail är inte verifierat", emailBackendInvalid: true});
                    break;
                default:
                    this.setState({errorMessage: "", fatalError: true});
                    break;
            }
        }
    }

    handleKeyClick(event: KeyboardEvent<HTMLInputElement>) {
        if (event.keyCode === 13) {
            this.handleSendClick();
        }
    }

    goBack() {
        this.setState({redirectTo: this.props.redirectTo});
    }
}

export default ForgotPasswordPage;
import * as React from "react"
import {KeyboardEvent} from "react"
import {mobile} from "../App"

interface Props {
    onChange: (currentValue: string, valid: boolean) => void
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
    autoFocus?: boolean
    invalid?: boolean
    disabled?: boolean
    value?: string
    placeholder?: string
    emptyIsValid: boolean
}

interface State {
    valid: boolean
    email: string
}

export default class EmailInput extends React.Component<Props, State> {

    EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            valid: false
        };
    }

    render() {
        const validInput = ((this.state.valid && !this.props.invalid) || this.state.email.length === 0);
        const mobileStyle = {
            width: "80vw",
            fontSize: "4rem",
            border: validInput ? "0.5rem solid var(--buttonBg)" : "0.5rem solid red"
        };
        const deskStyle = {
            width: "15rem",
            border: validInput ? "0.3rem solid var(--buttonBg)" : "0.3rem solid red"
        };
        const style = mobile ? mobileStyle : deskStyle;
        return (
            <input type="email" placeholder={ this.props.placeholder === undefined ? "E-mail" : this.props.placeholder}
                   onChange={this.onChange}
                   disabled={this.props.disabled}
                   style={style}
                   value={this.props.value}
                   name="E-mail" autoFocus={this.props.autoFocus} onKeyDown={this.props.onKeyDown}/>
        );
    }

    private onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const email = event.currentTarget.value;
        const valid = (event.currentTarget.value.length === 0 && this.props.emptyIsValid) ? true : this.EMAIL_REGEXP.test(email);
        this.setState(
            {
                email: email,
                valid: valid
            });
        this.props.onChange(email, valid);
    };
}
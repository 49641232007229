import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "../BasePage";
import {getContacts} from "../../api/api";
import {AccountDto, ContactsGroupDto, SessionDto} from "../../api/dtos";
import FullPageMessage from "../../components/FullPageMessage";
import Table from "../../components/Table";
import TableRow from "../../components/TableRow";
import TableText from "../../components/TableText";
import {mobile} from "../../App";
import ClipboardUtil from "../../util/ClipboardUtil";
import TableButton, {ButtonType} from "../../components/TableButton";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    loading: boolean
    fatalError: boolean
    contactsGroups: ContactsGroupDto[]
}

export default class ContactsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            fatalError: false,
            contactsGroups: []
        }
    }

    async componentDidMount() {
        const {groupplayId} = this.props.match.params;
        const {session} = this.props;
        if (session) {
            try {
                const contactsGroupDtos = await getContacts(groupplayId, session.sessionId);
                this.setState({contactsGroups: contactsGroupDtos, loading: false});
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    case "NOT_FOUND":
                        //just ignore..
                        this.setState({loading: false});
                        break;
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }
        }
    }

    render() {
        const {groupplayId} = this.props.match.params;
        const {loading, fatalError, contactsGroups} = this.state;
        const {session} = this.props;
        if (!session || (!session.groupplayIds.includes(groupplayId) &&
            !session.adminGroupplayIds.includes(groupplayId))) {
            return (<Redirect to={'/gruppspel/' + groupplayId}/>);
        }
        if (session) {
            return (
                <BasePage {...this.props} loading={loading} fatalError={fatalError}>
                    {contactsGroups.length === 0 && <FullPageMessage text={"Det finns inget spelschema än.."}/>}
                    {session.adminGroupplayIds.includes(groupplayId) && contactsGroups.length > 0 &&
                    <button style={{width: mobile ? "35rem" : "9rem"}}
                            onClick={() => this.copyAllAddresses(contactsGroups)}>
                        Kopiera mailadr.
                    </button>}
                    {contactsGroups.map(group => this.renderGroup(group, session, groupplayId))}
                </BasePage>
            );
        }
    }

    private renderGroup = (group: ContactsGroupDto, session: SessionDto, groupplayId: string) => {
        return <Table key={group.groupNr}>
            <TableRow>
                <TableText>
                    <span style={{fontWeight: "bold"}}>Grupp {group.groupNr}</span>
                </TableText>
                {(session.adminGroupplayIds.includes(groupplayId) ||
                    group.players.map(pl => pl.id).includes(session.accountId)) &&
                <TableButton right="0" mobRight="0" type={ButtonType.COPY_MAIL_ADDRESSES}
                             onClick={() => this.copyAllAddresses([group])}/>}
            </TableRow>
            {group.players.map((player, index) => this.renderRow(player, (index + 1) === group.players.length))}
        </Table>
    };

    private renderRow = (account: AccountDto, last: boolean) => {
        const height = account.ccEmail === undefined ? (mobile ? "6rem" : "2rem") : (mobile ? "9rem" : "3.5rem");
        return <TableRow key={account.id} height={height} lastRow={last}>
            <TableText left="1rem" mobLeft="0.5rem"
                       mobFirstRow>{account.firstName} {account.lastName}</TableText>
            <TableText left="20rem" mobLeft="0.5rem" mobSecondRow><a
                href={"mailto:" + encodeURI(account.firstName + " " + account.lastName + " <" + account.email + ">")}>{account.email}</a></TableText>
            <TableText left="20rem" mobLeft="0.5rem" secondRow mobThirdRow><a
                href={"mailto:" + encodeURI(account.firstName + " " + account.lastName + " <" + account.ccEmail + ">")}>{account.ccEmail}</a></TableText>
            <TableText right="0" mobLeft="32rem" mobSecondRow>
                {mobile ? <a href={"tel:" + account.phone}>{account.phone}</a> : <>{account.phone}</>}
            </TableText>
        </TableRow>;
    };

    private copyAllAddresses = (contactsGroups: ContactsGroupDto[]) => {
        let mailAddresses: string[] = contactsGroups.flatMap((group) =>
            group.players.map(pl => pl.firstName + " " + pl.lastName + " <" + pl.email + ">"));
        //add ccMails also
        for(const group of contactsGroups) {
            for(const pl of group.players) {
                if(pl.ccEmail !== undefined) {
                    mailAddresses.push(pl.firstName + " " + pl.lastName + " <" + pl.ccEmail + ">")
                }
            }
        }
        ClipboardUtil.copyToClipboard(mailAddresses.join(", "));
    };
};
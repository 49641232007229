import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../BasePage"
import {
    BookingIdDto,
    BookingsWithTimeRatingsDto,
    BookingWithTimeRatingsDto,
    MatchDetailsDto,
    TimePlaceRequestDto,
    TimeRating
} from "../../api/dtos"
import {clearMatchTimePlace, getFreeBookings, getMatchDetails, newBookingForMatch, saveOwnBookingForMatch} from "../../api/api"
import DateUtil from "../../util/DateUtil"
import Spinner from "../../components/Spinner"
import {CANT_COLOR, GOOD_COLOR, MEDIUM_COLOR, RATHER_NOT_COLOR} from "../../util/Constants"
import {mobile} from "../../App"
import Table from "../../components/Table"
import TableRow from "../../components/TableRow"
import TableText from "../../components/TableText"
import TableButton, {ButtonType} from "../../components/TableButton"
import TennisBall from "../../components/TennisBall"
import DateInput from "../../components/DateInput"
import TextInput from "../../components/TextInput"
import HourInput from "../../components/HourInput"
import {LinkButton} from "../../components/LinkButton"

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }> & RouteComponentProps<{ matchId: string }>

enum MatchPageState {
    MENU = "MENU",
    NEW_TIMES = "NEW_TIMES",
    LOADING_BOOKINGS = "LOADING_BOOKINGS",
    OWN_BOOKING = "OWN_BOOKING"
}
interface State {
    fatalError: boolean
    loading: boolean
    matchDetails?: MatchDetailsDto
    redirectTo?: string
    errorMessage?: string
    newTimes?: BookingsWithTimeRatingsDto,
    state: MatchPageState
    ownBookingDate?: string
    ownBookingTime?: number
    ownBookingCourt?: string
    ownBookingDateValid: boolean
    ownBookingTimeValid: boolean
    ownBookingError?: string
}

export default class MatchPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: true,
            state: MatchPageState.MENU,
            ownBookingTimeValid: false,
            ownBookingDateValid: false
        };
    }

    async componentDidMount() {
        if (this.props.session) {
            const {groupplayId, matchId} = this.props.match.params;
            try {
                const matchDetails = await getMatchDetails(groupplayId, matchId, this.props.session.sessionId);
                this.setState({matchDetails: matchDetails, loading: false});
                if(matchDetails.timePlace && matchDetails.timePlace.ownBooking) {
                    this.setState({
                        ownBookingTime: matchDetails.timePlace.time.hour,
                        ownBookingDate: matchDetails.timePlace.time.date,
                        ownBookingCourt: matchDetails.timePlace.court,
                        ownBookingDateValid: true,
                        ownBookingTimeValid: true
                    })
                }

            } catch (error:any) {
                console.log('ERROR:' + error.type);
                switch (error.type) {
                    case "PERMISSION_DENIED":
                        //TODO! nicer handling..
                        this.setState({fatalError: true, loading: false});
                        break;
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }
        } else {
            console.log("No session!")
        }
    }

    render() {
        const {groupplayId, matchId} = this.props.match.params;
        const matchIdNumber = parseInt(matchId);
        const {matchDetails, state, newTimes} = this.state;
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        if (!this.props.session) {
            return (<Redirect to={'/gruppspel/' + groupplayId}/>);
        }
        const smallSize = mobile ? "3rem" : "1rem";

        return (
            <BasePage {...this.props} loading={this.state.loading} sessionRequired={true}
                      fatalError={this.state.fatalError}>
                {matchDetails &&
                <div style={{margin: "auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {this.renderMatchInfo(matchDetails, smallSize)}

                    {state === "LOADING_BOOKINGS" && <>
                        <Spinner/>
                        <span style={{fontSize: smallSize, margin: "auto"}}>Hämtar bokningar från</span>
                        <span style={{fontSize: smallSize, margin: "auto"}}>bokningssystemet...</span>
                    </>}
                    {state === "MENU" && this.renderButtons(matchDetails, groupplayId, matchIdNumber, smallSize)}
                    {state === "NEW_TIMES" && newTimes && this.renderNewTimes(newTimes, groupplayId, matchIdNumber)}
                    {state === "OWN_BOOKING" && this.renderOwnBookingForm()}
                </div>}
            </BasePage>
        );
    }

    private renderMatchInfo = (matchDetails: MatchDetailsDto, smallSize: string) => {
        const containerWidth = mobile ? "80vw" : "20rem";
        const fontSize = mobile ? "4.5rem" : "2rem";
        const smallFontSize = mobile ? "2rem" : "1rem";
        const name1 = matchDetails.player1.firstName + " " + matchDetails.player1.lastName;
        const name2 = matchDetails.player2.firstName + " " + matchDetails.player2.lastName;
        const email1 = matchDetails.player1.email;
        const email2 = matchDetails.player2.email;
        const phone1 = matchDetails.player1.phone;
        const phone2 = matchDetails.player2.phone;
        return (
            <div style={{
                width: containerWidth, display: "flex", flexDirection: "column", alignItems: "center",
                fontSize: fontSize, marginBottom: "1rem"
            }}>
                <span>{name1} {matchDetails.ballPlayer === 1 && <TennisBall size="1em" marginBottom="-0.5rem"/>}</span>
                {mobile ?
                    <a style={{fontSize: smallFontSize}} href={"tel:" + phone1}>{phone1}</a>
                    :
                    <span style={{fontSize: smallFontSize}}>{phone1}</span>}
                <a style={{fontSize: smallFontSize, marginTop: mobile ? "1rem" : 0}}
                   href={"mailto:" + name1 + "<" + email1 + ">"}>{email1}</a>
                <span style={{fontSize: smallSize, marginTop: "1.5rem", marginBottom: "1.5rem"}}>vs</span>
                <span>{name2} {matchDetails.ballPlayer === 2 && <TennisBall size="1em" marginBottom="-0.5rem"/>}</span>
                {mobile ?
                    <a style={{fontSize: smallFontSize}} href={"tel:" + phone2}>{phone2}</a>
                    :
                    <span style={{fontSize: smallFontSize}}>{phone2}</span>}
                <a style={{fontSize: smallFontSize, marginTop: mobile ? "1rem" : 0, marginBottom: "2rem"}}
                   href={"mailto:" + name2 + "<" + email2 + ">"}>{email2}</a>
                {matchDetails.timePlace && matchDetails.timePlace.ownBooking &&
                    <span style={{fontSize: smallSize}}> <span className="pulsate-white">OBS!</span> Egen bokad tid:</span>}
                {matchDetails.timePlace && <span
                    style={{fontSize: smallSize}}>{DateUtil.dayName(matchDetails.timePlace.time.day)} {matchDetails.timePlace.time.date}</span>}
                {matchDetails.timePlace ? <span style={{fontSize: smallSize}}>kl {DateUtil.format(matchDetails.timePlace.time.hour, matchDetails.timePlace.time.minute)} {matchDetails.timePlace.court}</span> :
                    <span style={{fontSize: smallSize}}> Obokad </span>}
                {matchDetails.ballPlayer &&
                    <span style={{fontSize: smallSize, margin: "1rem"}}><TennisBall size="1em"/> = ska ha bollar med sig.</span>}
            </div>
        );
    }

    private renderButtons = (matchDetails: MatchDetailsDto, groupplayId: string, matchId: number, smallSize: string) => {
        const buttonWidth = mobile ? "80vw" : "13rem";
        return (<>
                {matchDetails.resultEditable &&
                <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                        onClick={() => this.resultClicked(groupplayId, matchId)}>Resultat
                </button>}
                {matchDetails.movable && matchDetails.timePlace &&
                <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                        onClick={() => this.clearMatchTimePlaceClicked(groupplayId, matchId)}>Ledigställ
                    tid
                </button>}
                {matchDetails.movable && <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                                                 onClick={() => this.bookNewTimeClicked(groupplayId, matchId)}>Boka
                    ny tid
                </button>}
                {matchDetails.movable && <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                                                 onClick={() => this.ownBookingClicked()}>
                    Egen bokning
                </button>}
                <LinkButton style={{marginTop: "1rem", fontSize: smallSize}} onClick={() => this.goBack(groupplayId)}/>
            </>
        );
    }

    private renderOwnBookingForm = () => {
        const smallSize = mobile ? "3rem" : "1rem";
        const {ownBookingDate, ownBookingTime, ownBookingCourt} = this.state;
        return (
            <>
                <div style={{marginTop:"1rem", fontSize:smallSize}}>Egen bokad tid att</div>
                <div style={{fontSize:smallSize}}>använda till denna match:</div>
                {this.state.ownBookingError && <div style={{fontSize: smallSize, color: "red"}}>{this.state.ownBookingError}</div>}

                <DateInput onChange={this.onOwnBookingDateChange} value={ownBookingDate}/>
                <HourInput onChange={this.onOwnBookingTimeChange} value={ownBookingTime} />
                <TextInput onChange={this.onOwnBookingCourtChange} placeHolder={"bana"} width="3rem" mobileWidth="12rem" value={ownBookingCourt}/>

                <button onClick={this.saveOwnBooking} disabled={this.ownBookingSaveDisabled()}>Spara</button>

                <LinkButton style={{margin: "0.5rem", fontSize: smallSize}} onClick={() => this.setState({state: MatchPageState.MENU})}/>
            </>
        );
    }

    private saveOwnBooking = async () => {
        const {groupplayId, matchId} = this.props.match.params;
        const {ownBookingDate, ownBookingTime, ownBookingCourt} = this.state;
        if (this.props.session && ownBookingDate && ownBookingTime && ownBookingCourt) {
            const request : TimePlaceRequestDto = {
                date: ownBookingDate,
                time: ownBookingTime,
                court: ownBookingCourt
            };
            try {
                await saveOwnBookingForMatch(groupplayId, parseInt(matchId), request, this.props.session.sessionId)
                this.setState({redirectTo: "/gruppspel/" + groupplayId + "/schema"})
            } catch (error: any) {
                console.log('ERROR:' + error.type)
                switch (error.type) {
                    case "TIME_AFTER_ROUND_END":
                        this.setState({loading: false, ownBookingError: "Tiden är efter omgångens slut!"})
                        break
                    default:
                        this.setState({fatalError: true, loading: false})
                        break
                }
            }
        }
    }

    private ownBookingSaveDisabled = () : boolean => {
        const {ownBookingDateValid, ownBookingTimeValid, ownBookingCourt,
            ownBookingTime, ownBookingDate, matchDetails} = this.state;

        let ownBookingUnchanged = false;
        if(matchDetails && matchDetails.timePlace && matchDetails.timePlace.ownBooking) {
            ownBookingUnchanged = matchDetails.timePlace.court === ownBookingCourt &&
                matchDetails.timePlace.time.hour === ownBookingTime &&
            matchDetails.timePlace.time.date === ownBookingDate;
        }

        return ownBookingUnchanged || !ownBookingDateValid || !ownBookingTimeValid || ownBookingCourt === undefined || ownBookingCourt === '';
    }

    private onOwnBookingDateChange = (currentValue: string, valid: boolean) => {
        this.setState({ownBookingDate: currentValue, ownBookingDateValid: valid});
    }

    private onOwnBookingTimeChange = (currentValue: number, valid: boolean) => {
        this.setState({ownBookingTime: currentValue,ownBookingTimeValid: valid});
    }

    private onOwnBookingCourtChange = (currentValue: string) => {
        this.setState({ownBookingCourt: currentValue});
    }

    private renderNewTimes = (newTimes: BookingsWithTimeRatingsDto, groupplayId: string, matchId: number) => {
        const smallSize = mobile ? "3rem" : "1rem";
        if (newTimes.bookings.length === 0) {
            const fontSize = mobile ? "4.5rem" : "2rem";
            return <div style={{textAlign: "center", marginTop: "2rem"}}>
                <div style={{fontSize: fontSize}}>Det finns inga lediga tider tillgängliga..</div>
                <LinkButton style={{margin: "2rem", fontSize: smallSize}} onClick={() => this.setState({state: MatchPageState.MENU})}/>
            </div>
        }
        return (
            <>
                <LinkButton style={{margin: "0.5rem", fontSize: smallSize}}
                            onClick={() => this.setState({state: MatchPageState.MENU})}/>
                <div style={{display: "flex", fontSize: mobile ? "2rem" : "1rem"}}>
                        <div style={{width: mobile?"11rem":"6rem",height: mobile?"3rem":"1.4rem",backgroundColor: MatchPage.ratingToColor(TimeRating.GOOD),textAlign:"center",marginRight:"2rem"}}>
                            Passar bra
                        </div>
                        <div style={{width: mobile?"4rem":"3rem",height: mobile?"3rem":"1.4rem",backgroundColor: MatchPage.ratingToColor(TimeRating.MEDIUM),textAlign:"center",marginRight:"2rem"}}>
                            går
                        </div>
                        <div style={{width: mobile?"10rem":"6rem",height: mobile?"3rem":"1.4rem",backgroundColor: MatchPage.ratingToColor(TimeRating.RATHER_NOT),textAlign:"center",marginRight:"2rem"}}>
                            helst inte
                        </div>
                        <div style={{width: mobile?"8rem":"5rem",height: mobile?"3rem":"1.4rem",backgroundColor: MatchPage.ratingToColor(TimeRating.CANT),textAlign:"center"}}>
                            kan inte
                        </div>
                </div>
                <Table>
                    {newTimes.bookings.map(b => this.getBooking(b, groupplayId, matchId))}
                </Table>
            </>);
    }

    private getBooking = (bwtr: BookingWithTimeRatingsDto, groupplayId: string, matchId: number) => {
        const rowHeight = mobile ?
            (Math.max(0, bwtr.closeMatches.length - 1) * 1.8 + 6.5) + "rem" :
        (Math.max(0, bwtr.closeMatches.length - 2) * 0.8 + 2) + "rem";

        return (<TableRow key={bwtr.booking.id} height={rowHeight}>
            <TableText left="1rem" mobLeft="0.5rem"
                       mobFirstRow>{DateUtil.shortDayName(bwtr.booking.time.day)}</TableText>
            <TableText left="3rem" mobLeft="3.5rem" mobFirstRow>{bwtr.booking.time.date}</TableText>
            <TableText left="9rem" mobLeft="15rem"
                       mobFirstRow>kl {mobile ? bwtr.booking.time.hour : DateUtil.format(bwtr.booking.time.hour, bwtr.booking.time.minute)}</TableText>
            <TableText left="13.5rem" mobLeft="20rem" mobFirstRow>{bwtr.booking.court}</TableText>

            <TableText left="17rem" mobLeft="27rem" mobFirstRow>
                <div style={{
                    width: mobile ? "2.4rem" : "1.4rem",
                    height: mobile ? "2.4rem" : "1.4rem",
                    backgroundColor: MatchPage.ratingToColor(bwtr.ratingPlayer1)
                }}/>
            </TableText>
            <TableText left="19rem" mobLeft="30rem" mobFirstRow>
                <div style={{
                    width: mobile ? "2.4rem" : "1.4rem",
                    height: mobile ? "2.4rem" : "1.4rem",
                    backgroundColor: MatchPage.ratingToColor(bwtr.ratingPlayer2)
                }}/>
            </TableText>
            <TableText left="21rem" mobLeft="0">
                <div style={{
                    fontSize: mobile ? "85%" : "70%",
                    maxWidth: mobile ? "34rem" : "23rem",
                    marginTop: mobile ? "1.5rem" : "-0.25rem",
                    fontStyle: "italic"
                }}>{bwtr.closeMatches.map((str, idx) => {
                        return (<>{str} <br/></>);
                    }
                )}</div>
            </TableText>

            <TableButton type={ButtonType.BOOK} right="0" mobRight="0"
                         onClick={() => this.newBookingForMatch(groupplayId, matchId, bwtr.booking.id, bwtr.booking.time.date, bwtr.booking.time.hour)}/>
        </TableRow>);

    }

    private resultClicked = (groupplayId: string, matchId: number) => {
        this.setState({redirectTo: "/gruppspel/" + groupplayId + "/result/" + matchId})
    }

    private ownBookingClicked = () => {
        this.setState({state: MatchPageState.OWN_BOOKING});
    }

    private bookNewTimeClicked = async (groupplayId: string, matchId: number) => {
        const {session} = this.props;
        if (session) {
            this.setState({state: MatchPageState.LOADING_BOOKINGS});
            try {
                const newTimes: BookingsWithTimeRatingsDto = await getFreeBookings(groupplayId, matchId, session.sessionId);
                this.setState({newTimes: newTimes, state: MatchPageState.NEW_TIMES});
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }
        }
    }

    private clearMatchTimePlaceClicked = async (groupplayId: string, matchId: number) => {
        const {session} = this.props;
        if (session) {
            this.setState({loading: true});
            try {
                await clearMatchTimePlace(groupplayId, matchId, session.sessionId);
                this.setState({loading: false, redirectTo: "/gruppspel/" + groupplayId + "/schema"});
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }

        }
    }

    private newBookingForMatch = async (groupplayId: string, matchId: number, bookingId: string, bookingDate: string, bookingHour: number) => {
        const {session} = this.props;
        if (session) {
            this.setState({loading: true});
            try {
                const bookingIdDto: BookingIdDto = {
                    bookingId: bookingId,
                    date: bookingDate,
                    hour: bookingHour
                };
                await newBookingForMatch(groupplayId, matchId, bookingIdDto, session.sessionId);
                this.setState({loading: false, redirectTo: "/gruppspel/" + groupplayId + "/schema"});
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    case "BOOKING_ALREADY_USED":
                        //TODO: Show nicer message maybe?? but wont happen often...
                        this.setState({fatalError: true, loading: false});
                        break;
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }

        }
    }

    private goBack = (groupplayId: string) => {
        this.setState({redirectTo: "/gruppspel/" + groupplayId + "/schema"});
    }

    private static ratingToColor(timeRating: TimeRating): string {
        switch (timeRating) {
            case TimeRating.GOOD:
                return GOOD_COLOR;
            case TimeRating.RATHER_NOT:
                return RATHER_NOT_COLOR;
            case TimeRating.MEDIUM:
                return MEDIUM_COLOR;
            case TimeRating.CANT:
                return CANT_COLOR;
        }
    }
}

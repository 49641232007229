export interface GroupplayDto {
    id: string
    name: string
    nrOfRounds: number
}

export interface LoginRequestDto {
    email: string
    password: string
}

export interface SessionDto {
    sessionId: string
    firstName: string
    lastName: string
    accountId: number
    accountUid: string
    groupplayIds: string[]
    adminGroupplayIds: string[]
}

export interface NewAccountDto {
    firstName: string
    lastName: string
    phone: string
    email: string
    password: string
}

export interface AccountDto {
    id: number
    firstName: string
    lastName: string
    phone: string
    reminderMailHoursBeforeMatch: number
    resultMail: boolean
    email: string
    ccEmail?: string
    lastActivity: string
}

export interface AccountLightDto {
    id: number
    firstName: string
    lastName: string
}

export interface ContactsGroupDto {
    groupNr: number
    players: AccountDto[]
}

export interface AccountIdDto {
    accountId: number
}

export interface UpdateAccountDataDto {
    phone?: string
    reminderMailHoursBeforeMatch?: number
    resultMail?: boolean
    oldPassword?: string
    newPassword?: string
    ccEmail?: string
}

export interface ForgotPasswordRequestDto {
    email: string
}

export interface ResetPasswordRequestDto {
    linkId: string
    newPassword: string
}

export interface ResetPasswordLinkInfoDto {
    firstName: string
    lastName: string
    email: string
}

export enum PlayerState {
    NONE = "NONE",
    DONT_WANNA_JOIN = "DONT_WANNA_JOIN",
    WANNA_JOIN = "WANNA_JOIN",
    WANNA_PAUSE = "WANNA_PAUSE",
    WANNA_QUIT = "WANNA_QUIT",
    PLAYING = "PLAYING",
    PAUSE = "PAUSE",
    NO_ANSWER = "NO_ANSWER"
}

export interface PlayerDto {
    account: AccountDto
    groupNumber?: number
    state: PlayerState
    comment: string
    ongoingRoundInfo?: OngoingRoundInfoDto
}

export interface EmailDto {
    email: string
}

export interface OngoingRoundInfoDto {
    groupNumber: number
    position: number
    nrOfMatches: number
    nrOfLostWo: number
    nrOfMatchesPlayed: number
    points: number
    gameDiff: number
    setDiff: number
}

export interface InviteDto {
    linkId: string
    groupplayId: string
    groupplayName: string
    firstName: string
    lastName: string
    email: string
    hasAccount: boolean
    session?: SessionDto
    created: DateTimeDto
}

export interface NewAccountWithInviteDto {
    firstName: string
    lastName: string
    phone: string
    inviteLinkId: string
    password: string
}

export interface PlayersDto {
    players: PlayerDto[]
    invites: InviteDto[]
}

export interface InviteRequestDto {
    firstName: string
    lastName: string
    email: string
}

export interface UpdatePlayerGroupDto {
    accountId: number
    groupNumber?: number
}

export interface UpdatePlayerDto {
    accountId: number
    state: PlayerState
    comment: string
}

export interface PlayerStateDto {
    state: PlayerState
    groupplayName: string
    latestRoundEnd: string
    playedInLastRound: boolean
    activeSignupMail: boolean
    nextRoundStarts?: DateDto
    signupAnswerLatest?: DateDto
}

export interface TimeRatingDto {
    day: WeekDay
    time: number
    rating: TimeRating
}

export interface SearchAccountDto {
    searchString: string
}

export enum WeekDay {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
}

export enum TimeRating {
    GOOD = "GOOD",
    MEDIUM = "MEDIUM",
    RATHER_NOT = "RATHER_NOT",
    CANT = "CANT"
}

export interface NextRoundBookingsDto {
    summary: BookingsSummaryDto
    bookings: BookingDto[]
}

export interface DateTimeDto {
    date: string
    hour: number
    minute: number
    day: WeekDay
}

export interface DateDto {
    date: string
    day: WeekDay
}

export interface BookingDto {
    id: string
    court: string
    time: DateTimeDto
    dontUse?: boolean
}

export interface BookingIdDto {
    bookingId: string
    date: string
    hour: number
}

export interface UpdateNextRoundBookingsDto {
    nrOfExtraBookings: number
    dontUse: TimePlaceRequestDto[]
}

export interface TimePlaceRequestDto {
    court: string
    date: string
    time: number
}

export interface TimePlaceResponseDto {
    time: DateTimeDto
    court: string
    ownBooking: boolean
}

export enum GenerateScheduleState {
    QUEUED = "QUEUED",
    ONGOING = "ONGOING",
    NOT_ONGOING = "NOT_ONGOING",
    NOT_ENOUGH_BOOKINGS = "NOT_ENOUGH_BOOKINGS",
    NO_GROUPS = "NO_GROUPS"
}

export interface GenerateScheduleStatusDto {
    state: GenerateScheduleState
    ongoing?: OngoingDto
    schedule?: NextRoundScheduleDto
    maybeMissed: PlayerDto[]
}

export interface BookingsSummaryDto {
    nrOfMatches: number
    nrOfAvailableBookings: number
    maxNrOfMatchesForPlayer: number
    minNrOfMatchesForPlayer: number
    nrOfExtraBookings: number
    firstBooking?: BookingDto
    lastBooking?: BookingDto
    prevRoundEnd?: DateTimeDto
}

export interface OngoingDto {
    percentage: number
}

export interface NextRoundScheduleDto {
    totalPoints: number
    matches: NextRoundMatchDto[]
}

export interface NextRoundMatchDto {
    time: DateTimeDto
    court: string
    player1?: string
    player2?: string
    player1TimeRating: number
    player2TimeRating: number
    player1FreqRating: number
    player2FreqRating: number
}

export interface ScheduleDto {
    roundNumber: number
    openUntil: DateTimeDto
    rules: RulesDto
    matches: MatchDto[]
    now: string
    myGroupNr?: number
}

export interface RoundOverviewDto {
    roundNr: number
    firstMatchStart: DateTimeDto
    openUntil: DateTimeDto
    nrOfGroups: number
    nrOfPlayers: number
}

export interface MatchDto {
    id: number
    timePlace?: TimePlaceResponseDto
    player1: AccountLightDto
    player2: AccountLightDto
    ballPlayer?: number
    groupNr: number
    resultEditable: boolean
    movable: boolean
    result?: ResultDto
    resultString: string
}

export interface RulesDto {
    nrOfSets: number
    gamesPerSet: number
    tiebrakeGamesEqual: number
    pointsPerSet: number
    pointsPerGame: number
    pointsWoWinner: number
    pointsWoLoser: number
    pointsWoNoShowWinner: number
    pointsWoNoShowLoser: number
    pointsDrawSet: number
    pointsSetLeader: number
    pointsSetRunnerUp: number
    leaderByNrOfGames: number
}

export interface ResultDto {
    sets: SetResultDto[];
    player1Wo: boolean
    player2Wo: boolean
    player1WoNoShow: boolean
    player2WoNoShow: boolean
    player1Points: number
    player2Points: number
}

export interface SetResultDto {
    player1Games?: number
    player2Games?: number
}

export interface MatchDetailsDto {
    id: number
    timePlace?: TimePlaceResponseDto
    player1: AccountDto
    player2: AccountDto
    ballPlayer: number
    groupNr: number
    resultEditable: boolean
    movable: boolean
    result?: ResultDto
    resultString: string
    rules: RulesDto
}

export interface SaveResultDto {
    sets: SetResultDto[]
    player1Wo: boolean
    player2Wo: boolean
    player1WoNoShow: boolean
    player2WoNoShow: boolean
}

export interface BookingSystemSettingsDto {
    matchi?: MatchiConfigDto
    nasmo?: NasmoConfigDto
}

export interface BookingSettingsDto {
    unbookUnusedTimesEnabled: boolean
    unbookUnusedTimesHoursBefore: number
    matchMoveAllowedMinutesBefore: number
}

export interface GroupplaySettingsDto {
    name: string
    whoBringsNewBallsFeatureEnabled: boolean
}

export interface MatchiConfigDto {
    bookingUsername: string
    adminUsername: string
    adminPassword: string
}

export interface NasmoConfigDto {
    systemId: string
    bookingUsername: string
    adminUsername: string
    adminPassword: string
}

export interface CourtNameAliasDto {
    id: number
    courtName: string
    alias: string
}

export interface TestBookingSystemDto {
    futureBookingsFound: number
}

export interface RoundStandingsDto {
    roundNumber: number
    firstMatchStart: DateTimeDto
    openUntil: DateTimeDto
    groups: GroupStandingsDto[]
}

export interface GroupStandingsDto {
    groupNr: number
    players: PlayerStandingDto[]
}

export interface PlayerStandingDto {
    position: number
    player: AccountLightDto
    points: number
    wonGames: number
    lostGames: number
    wonSets: number
    lostSets: number
    playedMatches: number
    meetings: PlayerStandingMeetingsDto[]

}

export interface PlayerStandingMeetingsDto {
    position: number
    opponent: AccountDto
    points?: number
    resultString: string
}

export interface BookingWithTimeRatingsDto {
    booking: BookingDto,
    ratingPlayer1: TimeRating
    ratingPlayer2: TimeRating
    closeMatches: string[]
}

export interface BookingsWithTimeRatingsDto {
    bookings: BookingWithTimeRatingsDto[],
    player1: AccountDto,
    player2: AccountDto,
}

export interface UpdateRoundOpenUntilDto {
    date: string
    hour: number
    minute: number
}

export interface RuleParagraphDto {
    number: number
    heading: string
    text: string
}

export interface SendSignupMailsRequestDto {
    answerLatest: string
    nextRoundStarts: string
    sendTo: SendSignupsTo
}

export enum SendSignupsTo {
    ALL = "ALL",
    PAUSED = "PAUSED",
    NO_ANSWER = "NO_ANSWER"
}

export interface SignupDto {
    session: SessionDto,
    nextRoundStarts: DateDto,
    answerLatest: DateDto
}

export enum SignupAnswer {
    YES = "YES",
    NO = "NO",
    PAUSE = "PAUSE"
}

export interface BillingDto {
    player: AccountDto
    description: string
    totalPrice: string
}

export enum ErrorType {
    MISSING_PARAMETER = "MISSING_PARAMETER",
    LOGIN_FAILED_PASSWORD = "LOGIN_FAILED_PASSWORD",
    EMAIL_NOT_FOUND = "EMAIL_NOT_FOUND",
    EMAIL_VERIFICATION_NEEDED = "EMAIL_VERIFICATION_NEEDED",
    EMAIL_ALREADY_VERIFIED = "EMAIL_ALREADY_VERIFIED",
    EMAIL_VERIFICATION_FAILED = "EMAIL_VERIFICATION_FAILED",
    EMAIL_ALREADY_REGISTERED = "EMAIL_ALREADY_REGISTERED",
    NOT_FOUND = "NOT_FOUND",
    INVALID_GROUPPLAY_ID = "INVALID_GROUPPLAY_ID",
    INVALID_SESSION = "INVALID_SESSION",
    PERMISSION_DENIED = "PERMISSION_DENIED",
    COMMUNICATION_PROBLEM_WITH_BOOKINGSYSTEM = "COMMUNICATION_PROBLEM_WITH_BOOKINGSYSTEM",
    NO_NEXT_ROUND_SCHEDULE = "NO_NEXT_ROUND_SCHEDULE",
    DATABASE_ERROR = "DATABASE_ERROR",
    SERVER_ERROR = "SERVER_ERROR",
    INVALID_RESULT = "INVALID_RESULT",
    INVALID_RULES = "INVALID_RULES",
    INVALID_EMAIL = "INVALID_EMAIL",
    WRONG_OLD_PASSWORD = "WRONG_OLD_PASSWORD",
    INVALID_LINK_ID = "INVALID_LINK_ID",
    ALREADY_IN_GROUPPLAY = "ALREADY_IN_GROUPPLAY",
    DUPLICATE = "DUPLICATE",
    SCHEDULE_GENERATION_IN_PROGRESS = "SCHEDULE_GENERATION_IN_PROGRESS",
    BOOKING_SYSTEM_CONFIG_NOT_FOUND = "BOOKING_SYSTEM_CONFIG_NOT_FOUND",
    NOT_ENOUGH_BOOKINGS = "NOT_ENOUGH_BOOKINGS",
    BOOKING_NOT_FOUND = "BOOKING_NOT_FOUND",
    BOOKING_ALREADY_USED = "BOOKING_ALREADY_USED",
    INCORRECT_NAME = "INCORRECT_NAME",
    INVITE_ALREADY_GOT_ACCOUNT = "INVITE_ALREADY_GOT_ACCOUNT",
    INVALID_STATE = "INVALID_STATE",
    TIME_AFTER_ROUND_END = "TIME_AFTER_ROUND_END",
    SEND_MAIL_FAILURE = "SEND_MAIL_FAILURE"
}

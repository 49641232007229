import BasePage, {BasePageProps} from "../../BasePage"
import {RouteComponentProps} from "react-router"
import * as React from "react"
import {AccountDto, ErrorType, InviteRequestDto} from "../../../api/dtos"
import {addPlayer, invite} from "../../../api/api"
import {Link, Redirect} from "react-router-dom"
import BorderWithText from "../../../components/BorderWithText"
import TextInput from "../../../components/TextInput"
import EmailInput from "../../../components/EmailInput"
import {mobile} from "../../../App"
import TypeAheadInput from "../../../components/TypeAheadInput";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    inviteEmailValid: boolean
    inviteEmail: string
    addAccount?: AccountDto
    firstName: string
    lastName: string
    inviteError?: string
    addError?: string
    fatalError: boolean
    loading: boolean
    goBack: boolean
}

export class AddPlayerPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            fatalError: false,
            inviteEmailValid: false,
            inviteEmail: '',
            firstName: '',
            lastName: '',
            goBack: false
        };
        this.handleInviteEmailChange = this.handleInviteEmailChange.bind(this);
        this.handleInviteFirstNameChange = this.handleInviteFirstNameChange.bind(this);
        this.handleInviteLastNameChange = this.handleInviteLastNameChange.bind(this);
        this.handleInviteClick = this.handleInviteClick.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this);
        this.handleAddAccountChange = this.handleAddAccountChange.bind(this);
    }

    render() {

        const {groupplayId} = this.props.match.params;
        const {goBack} = this.state;

        if(goBack) {
           return (<Redirect to={"/gruppspel/" + groupplayId + "/admin/players"}/>);
        }
        return (
            <BasePage adminPage={true} loading={this.state.loading} {...this.props} fatalError={this.state.fatalError}>
                <BorderWithText text="Bjud in spelare (utan konto)">

                    <div>
                        <br/>
                        Spelaren får en inbjudan via mail med instruktioner hur hen skapar ett konto (om hen inte redan har det).
                        Därefter möjlighet att acceptera inbjudan och skriva en kommentar.
                        <br/><br/>
                    </div>

                    <div style={{color: "red", width: "40rem"}}>{this.state.inviteError}</div>
                    <TextInput placeHolder={"Förnamn"} onChange={this.handleInviteFirstNameChange}
                               disabled={this.state.loading} value={this.state.firstName}/>
                    <TextInput placeHolder={"Efternamn"} onChange={this.handleInviteLastNameChange}
                               disabled={this.state.loading} value={this.state.lastName}/>
                    <EmailInput onChange={this.handleInviteEmailChange} emptyIsValid={false}
                                disabled={this.state.loading} value={this.state.inviteEmail}/>
                    <button onClick={this.handleInviteClick} disabled={this.inviteButtonDisabled()}>Maila inbjudan
                    </button>
                </BorderWithText>

                <BorderWithText text="Lägg till spelare (som redan har konto)">
                    <br/> Spelare får statusen "vill börja". Inget skickas till spelaren.
                    <br/><br/>
                    <div style={{color: "red", width: "40rem"}}>{this.state.addError}</div>
                    <TypeAheadInput onChange={this.handleAddAccountChange} value={this.state.addAccount} groupplayId={groupplayId} sessionId={this.props.session!.sessionId}/>

                    <button onClick={this.handleAddClick} disabled={this.addButtonDisabled()}>Lägg till</button>
                </BorderWithText>

                <Link style={{alignSelf: "end", marginRight: "2rem", fontSize:mobile?"3rem":"1rem"}} to={"/gruppspel/" + groupplayId + "/admin/players"}>
                    Tillbaka
                </Link>
            </BasePage>
        );
    }

    handleInviteFirstNameChange(value: string) {
        this.setState({firstName: value});
    }

    handleInviteLastNameChange(value: string) {
        this.setState({lastName: value});
    }

    handleInviteEmailChange(value: string, valid: boolean) {
        this.setState({inviteEmail: value, inviteEmailValid: valid});
    }

    handleAddAccountChange(account?: AccountDto) {
        this.setState({addAccount: account});
    }

    inviteButtonDisabled() : boolean {
        const {inviteEmailValid, firstName, lastName} = this.state;
        return !inviteEmailValid || firstName === '' || lastName === '';
    }

    addButtonDisabled() : boolean {
        const {addAccount} = this.state;
        return !addAccount;
    }

    async handleAddClick() {
        const {addAccount} = this.state;
        if (this.props.session && addAccount) {
            this.setState({loading: true, addError: undefined});

            const {groupplayId} = this.props.match.params;
            try {
                await addPlayer(groupplayId, {email: addAccount.email}, this.props.session.sessionId);
                this.setState({goBack: true});
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    case "ALREADY_IN_GROUPPLAY":
                        this.setState({addError: "Spelaren verkar redan vara med i gruppspelet: " + error.message, loading: false})
                        break;
                    case "EMAIL_NOT_FOUND":
                        this.setState({addError: "Spelaren har inget konto hos gruppspelet.se, skicka en inbjudan istället?", loading: false});
                        break;
                    case "INVALID_EMAIL":
                        this.setState({addError: "Ogiltig Email-address.", loading: false});
                        break;
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }

    async handleInviteClick() {
        if (this.props.session) {
            this.setState({loading: true, inviteError: undefined});
            const {firstName, lastName, inviteEmail} = this.state;
            const {groupplayId} = this.props.match.params;
            const inviteRequest: InviteRequestDto = {
                email: inviteEmail,
                firstName: firstName,
                lastName: lastName
            };
            try {
                await invite(groupplayId, inviteRequest, this.props.session.sessionId);
                this.setState({goBack: true});
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    case ErrorType.INCORRECT_NAME:
                        this.setState({
                            inviteError: "Det finns redan ett konto med den mailen men med " + error.message,
                            loading: false
                        });
                        break;
                    case ErrorType.ALREADY_IN_GROUPPLAY:
                        this.setState({
                            inviteError: "Spelaren verkar redan vara med i gruppspelet: " + error.message,
                            loading: false
                        });
                        break;
                    case ErrorType.DUPLICATE:
                        this.setState({inviteError: "Spelaren verkar redan ha en inbjudan.", loading: false});
                        break;
                    case ErrorType.SEND_MAIL_FAILURE:
                        this.setState({inviteError: "Misslyckades att maila: " + error.message});
                        break;
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }
}

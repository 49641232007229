import * as React from "react"
import Checkbox from "../../../components/Checkbox"
import {GroupplaySettingsDto} from "../../../api/dtos"
import BasePage, {BasePageProps} from "../../BasePage"
import {getGroupplaySettings, saveGroupplaySettings} from "../../../api/api"
import {RouteComponentProps} from "react-router"
import {mobile} from "../../../App"
import Panel from "../../../components/Panel"
import TextInput from "../../../components/TextInput"

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    groupplaySettings?: GroupplaySettingsDto
    name: string
    whoBringsNewBallsFeatureEnabled: boolean
    loading: boolean
    fatalError: boolean
}

export default class GroupplaySettingsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            fatalError: false,
            name: "",
            whoBringsNewBallsFeatureEnabled: false
        }
    }

    async componentDidMount() {
        const {groupplayId} = this.props.match.params
        if (this.props.session) {
            try {
                const dto: GroupplaySettingsDto = await getGroupplaySettings(groupplayId, this.props.session.sessionId)
                this.setState({
                    groupplaySettings: dto,
                    name: dto.name,
                    whoBringsNewBallsFeatureEnabled: dto.whoBringsNewBallsFeatureEnabled,
                    loading: false
                })
            } catch (error: any) {
                console.log('ERROR:' + error.type)
                switch (error.type) {
                    default:
                        this.setState({fatalError: true})
                        break
                }
            }
        }
    }

    render() {
        const {
            loading, fatalError, name, whoBringsNewBallsFeatureEnabled
        } = this.state
        const fontSize = mobile ? "3rem" : "1rem"
        const textMargin = mobile ? "1.5rem" : "0.5rem"
        return (
            <BasePage {...this.props} adminPage={true} loading={loading} fatalError={fatalError}>
                <div style={{margin: "auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <Panel center={true}>
                        <div style={{margin: "0 auto 2rem auto", width: "fit-content"}}>
                            <Checkbox checked={whoBringsNewBallsFeatureEnabled} onChange={this.whoBringsNewBallsFeatureEnabledClicked}
                                      text="Ange i spelschemat vem som ska ha bollar med sig"
                                      id={"unbookingEnabled"}/>
                        </div>
                            <span style={{fontSize: fontSize, marginLeft: textMargin}}>Gruppspelets namn:</span>
                            <TextInput value={name} onChange={this.handleNameChange}
                                       width={mobile ? "80vw" : "20rem"} placeHolder={"Gruppspelets namn"}/>
                    </Panel>
                    <button style={{padding: "0 1rem"}} onClick={this.handleSaveClick}
                            disabled={this.saveDisabled()}>Spara
                    </button>
                </div>
            </BasePage>
        )
    }

    private handleSaveClick = async () => {
        if (this.props.session) {
            this.setState({loading: true})
            const {groupplayId} = this.props.match.params
            const {name, whoBringsNewBallsFeatureEnabled} = this.state
            const request: GroupplaySettingsDto = {
                name: name,
                whoBringsNewBallsFeatureEnabled: whoBringsNewBallsFeatureEnabled,
            }
            try {
                await saveGroupplaySettings(request, groupplayId, this.props.session.sessionId)
                this.setState({
                    loading: false,
                    groupplaySettings: request
                })
            } catch (error: any) {
                console.log('error type:' + error.type)
                switch (error.type) {
                    default:
                        console.log("FATAL ERROR")
                        this.setState({fatalError: true})
                        break
                }
            }
        }
    }

    private saveDisabled = (): boolean => {
        const {name, whoBringsNewBallsFeatureEnabled, groupplaySettings} = this.state
        return groupplaySettings !== undefined &&
            groupplaySettings.name === name &&
            groupplaySettings.whoBringsNewBallsFeatureEnabled === whoBringsNewBallsFeatureEnabled
    }


    private handleNameChange = (currentValue: string) => {
        this.setState({name: currentValue})
    }

    private whoBringsNewBallsFeatureEnabledClicked = (checked: boolean) => {
        this.setState({whoBringsNewBallsFeatureEnabled: checked})
    }
}

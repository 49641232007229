import * as React from "react"
import {KeyboardEvent} from "react"
import {Link, Redirect, RouteComponentProps} from "react-router-dom"
import {login} from "../../api/api"
import Checkbox from "../../components/Checkbox"
import BasePage, {BasePageProps} from "../BasePage"
import EmailInput from "../../components/EmailInput"
import TextInput from "../../components/TextInput"
import {ErrorMessage} from "../../components/ErrorMessage"
import {mobile} from "../../App"
import {LinkButton} from "../../components/LinkButton"
import {ErrorType} from "../../api/dtos"

interface LoginPageProps {
    redirectTo: string
}

interface State {
    email: string,
    password: string,
    rememberMe: boolean
    errorMessage?: string
    redirectTo?: string
    fatalError: boolean
    emailCompValid: boolean
    emailBackendInvalid: boolean
    passwordInvalid: boolean
    loading: boolean
}

type Props = BasePageProps & LoginPageProps & RouteComponentProps<{ groupplayId: string }>

class LoginPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            passwordInvalid: false,
            rememberMe: false,
            fatalError: false,
            emailCompValid: true,
            emailBackendInvalid: false,
            loading: false
        };
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRememberMeChange = this.handleRememberMeChange.bind(this);
        this.handleKeyClick = this.handleKeyClick.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    handleEmailChange(currentValue: string, valid: boolean) {
        this.setState({email: currentValue, emailCompValid: valid, emailBackendInvalid: false, errorMessage: ''});
    }

    handlePasswordChange(currentValue: string) {
        this.setState({password: currentValue, passwordInvalid: false, errorMessage: ''});
    }

    handleRememberMeChange(checked : boolean) {
        this.setState({rememberMe: checked})
    }

    render() {
        const fontSize = mobile ? "3rem" : "1rem";
        const textMargin = mobile ? "1.5rem" : "0.5rem";
        return (
            this.state.redirectTo ?
                <Redirect to={this.state.redirectTo}/> :
                <BasePage {...this.props} fatalError={this.state.fatalError} loading={this.state.loading}>
                    <div style={{margin: "auto", display: "flex", flexDirection: "column"}}>
                        <EmailInput onChange={this.handleEmailChange} emptyIsValid={false}
                                    invalid={this.state.emailBackendInvalid} onKeyDown={this.handleKeyClick}
                                    autoFocus={true}/>
                        <TextInput onChange={this.handlePasswordChange} placeHolder={"Lösenord"}
                                   onKeyDown={this.handleKeyClick} width="15rem"
                                   invalid={this.state.passwordInvalid} password={true}/>
                        <ErrorMessage text={this.state.errorMessage}/>
                        <button onClick={this.handleLoginClick}
                                disabled={(!this.state.emailCompValid || this.state.emailBackendInvalid || this.state.password.length === 0)}>
                            Login
                        </button>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            fontSize: fontSize,
                            margin: textMargin
                        }}>
                            <span style={{marginRight: "1rem"}}>Kom ihåg mig: </span>
                            <Checkbox id="rememberMe" checked={this.state.rememberMe} onChange={this.handleRememberMeChange}/>
                        </div>
                        <Link to="/forgotPassword" style={{fontSize: fontSize, margin: textMargin, textAlign: "right"}}>Glömt
                            lösenord?</Link>
                        <Link to="/newAccount" style={{fontSize: fontSize, margin: textMargin, textAlign: "right"}}>Inget
                            konto?</Link>
                        <LinkButton style={{fontSize: fontSize, margin: textMargin, textAlign: "right"}} onClick={this.goBack}/>
                    </div>
                </BasePage>
        );
    }

    async handleLoginClick() {
        this.setState({errorMessage: '', loading: true});
        try {
            const sessionDto = await login({email: this.state.email, password: this.state.password});
            this.props.onSessionChange(this.state.rememberMe, sessionDto);
            this.setState({redirectTo: this.props.redirectTo});
        } catch (error:any) {
            switch (error.type) {
                case ErrorType.INVALID_EMAIL:
                    this.setState({errorMessage: "Felaktig E-mail address", emailBackendInvalid: true, loading: false});
                    break;
                case ErrorType.LOGIN_FAILED_PASSWORD:
                    this.setState({errorMessage: "Fel lösenord", passwordInvalid: true, loading: false});
                    break;
                case ErrorType.EMAIL_NOT_FOUND:
                    this.setState({
                        errorMessage: "Inget konto med den E-mail adressen hittades",
                        emailBackendInvalid: true, loading: false
                    });
                    break;
                case ErrorType.EMAIL_VERIFICATION_NEEDED:
                    this.setState({
                        errorMessage: "Kontots E-mail är inte verifierat",
                        emailBackendInvalid: true,
                        loading: false
                    });
                    break;
                default:
                    this.setState({errorMessage: "", fatalError: true, loading: false});
                    break;
            }
        }
    }

    handleKeyClick(event: KeyboardEvent<HTMLInputElement>) {
        if (event.keyCode === 13) {
            this.handleLoginClick();
        }
    }

    goBack() {
        this.setState({redirectTo: this.props.redirectTo});
    }
}

export default LoginPage;
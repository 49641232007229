import BasePage, {BasePageProps} from "../../BasePage"
import {RouteComponentProps} from "react-router"
import * as React from "react"
import {Link, Redirect} from "react-router-dom"
import {mobile} from "../../../App"
import DateInput from "../../../components/DateInput";
import {sendSignupMails} from "../../../api/api";
import Checkbox from "../../../components/Checkbox";
import {SendSignupsTo} from "../../../api/dtos";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    fatalError: boolean
    loading: boolean
    goBack: boolean
    answerLatestDateString: string
    answerLatestDateValid: boolean
    nextRoundStartsDateString: string
    nextRoundStartsDateValid: boolean
    sendTo: SendSignupsTo
}

export class SendSignupsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            fatalError: false,
            goBack: false,
            answerLatestDateString: '',
            answerLatestDateValid: false,
            nextRoundStartsDateString: '',
            nextRoundStartsDateValid: false,
            sendTo: SendSignupsTo.NO_ANSWER
        };
    }

    render() {
        const {groupplayId} = this.props.match.params;
        const {goBack, answerLatestDateString, nextRoundStartsDateString} = this.state;

        if (goBack) {
            return (<Redirect to={"/gruppspel/" + groupplayId + "/admin/players"}/>);
        }
        return (
            <BasePage adminPage={true} center={true} loading={this.state.loading} {...this.props}
                      fatalError={this.state.fatalError}>

                <div style={{display: "flex", flexDirection: "column", marginBottom: "2rem"}}>
                    <span>Skicka mail med anmälan till:</span>
                    <div style={{marginTop: "1rem"}}>
                        <div style={{marginBottom: mobile ? "3rem" : "0.5rem"}}>
                            <Checkbox onChange={() => this.checkBoxClick(SendSignupsTo.PAUSED)} id={"paused"}
                                      text="De som har status Pause/Ej Svarat"
                                      checked={this.state.sendTo === SendSignupsTo.PAUSED}/>
                        </div>
                        <div style={{marginBottom: mobile ? "3rem" : "0.5rem"}}>
                            <Checkbox onChange={() => this.checkBoxClick(SendSignupsTo.NO_ANSWER)} id={"paused"}
                                      text="De som har status Ej Svarat"
                                      checked={this.state.sendTo === SendSignupsTo.NO_ANSWER}/>
                        </div>
                        <div>
                            <Checkbox onChange={() => this.checkBoxClick(SendSignupsTo.ALL)} id={"paused"}
                                      text="ALLA (sätter om status till Ej Svarat på alla)"
                                      checked={this.state.sendTo === SendSignupsTo.ALL}/>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    I mailet får dom frågan om dom vill vara med i nästa omgång och kan välja mellan alternativen: <br/><br/>
                    <ul>
                        <li>Ja (Spelar/Vill börja)</li>
                        <li>Avvakta (Vill pausa/Paus)</li>
                        <li>Nej (Vill Sluta)</li>
                    </ul>
                </div>

                <table>
                    <tbody>
                    <tr>
                        <td align="right">Svara senast:</td>
                        <td><DateInput onChange={this.handleAnswerLatestDateChange} value={answerLatestDateString}/>
                        </td>
                    </tr>
                    <tr>
                        <td align="right">Nästa omgång börjar:</td>
                        <td><DateInput onChange={this.handleNextRoundStartsDateChange}
                                       value={nextRoundStartsDateString}/></td>
                    </tr>
                    </tbody>
                </table>

                <button onClick={this.handleSendClick} disabled={this.sendButtonDisabled()}>Skicka anmälningsmail
                </button>

                <Link style={{alignSelf: "end", marginRight: "2rem", fontSize: mobile ? "3rem" : "1rem"}}
                      to={"/gruppspel/" + groupplayId + "/admin/players"}>
                    Tillbaka
                </Link>
            </BasePage>
        );
    }

    handleAnswerLatestDateChange = (value: string, valid: boolean) => {
        this.setState({answerLatestDateString: value, answerLatestDateValid: valid});
    }

    handleNextRoundStartsDateChange = (value: string, valid: boolean) => {
        this.setState({nextRoundStartsDateString: value, nextRoundStartsDateValid: valid});
    }

    sendButtonDisabled = () => {
        return !this.state.answerLatestDateValid || !this.state.nextRoundStartsDateValid
    }

    checkBoxClick = (newSendTo: SendSignupsTo) => {
        this.setState({sendTo: newSendTo})
    }


    handleSendClick = async () => {
        if (this.props.session) {
            this.setState({loading: true});

            const {groupplayId} = this.props.match.params;
            try {
                console.log('send signups for ' + groupplayId)
                await sendSignupMails(groupplayId, {
                    answerLatest: this.state.answerLatestDateString,
                    nextRoundStarts: this.state.nextRoundStartsDateString,
                    sendTo: this.state.sendTo
                }, this.props.session.sessionId);
                this.setState({goBack: true});
            } catch (error: any) {
                console.log('ERROR' + error);
                this.setState({fatalError: true});
            }
        }
    }
}

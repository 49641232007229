import * as React from "react"
import {Swipeable} from 'react-swipeable'
import {GroupStandingsDto, RoundStandingsDto} from "../../api/dtos"
import {mobile} from "../../App"

interface Props {
    standings: RoundStandingsDto
    groupplayId: string
}

interface State {
    columnChanged: boolean
}

export default class Standings extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            columnChanged: false
        };
        this.changeColumns = this.changeColumns.bind(this);
    }

    render() {
        return (
            <div style={{width: "max-content", margin: "1rem auto 10rem auto"}}>
                {this.props.standings.groups.map(
                    group => mobile ? this.getMobileGroup(group) : this.getDesktopGroup(group))}
            </div>

        );
    }

    getDesktopGroup(group: GroupStandingsDto) {
        return (<div key={"group-" + group.groupNr} style={{
            position: "relative",
            padding: "0.5rem",
            width: "47rem",
            margin: "0 0.5rem 1.5rem 0.5rem",
            backgroundColor: "var(--panelBg)",
            color: "var(--panelText)",
            borderRadius: "0.3rem",
            fontSize: "1rem"
        }}>

            <table className="white-bordered" style={{width: "47rem", textAlign: "right", marginTop: "1.75rem"}}>
                <thead>
                <tr>
                    <th colSpan={2} style={{width: "1rem", textAlign: "left"}}>
                        <span style={{fontWeight: "bold", fontSize: "1.5rem"}}>Grupp {group.groupNr}</span>
                    </th>
                    {group.players.map(playerStanding => {
                        return (<th key={playerStanding.player.id} className="rotated" style={{width: "2rem"}}>
                            <div><span>{playerStanding.position} {playerStanding.player.firstName}</span></div>
                        </th>)
                    })}
                    <th className="rotated" style={{width: "2rem"}}>
                        <div><span>spelade</span></div>
                    </th>
                    <th className="rotated" style={{width: "4rem"}}>
                        <div><span style={{bottom: "1rem", left: "0.5rem"}}>set</span></div>
                    </th>
                    <th className="rotated" style={{width: "2rem"}}>
                        <div><span>set +/-</span></div>
                    </th>
                    <th className="rotated" style={{width: "4rem"}}>
                        <div><span style={{bottom: "1rem", left: "0.5rem"}}>gem</span></div>
                    </th>
                    <th className="rotated" style={{width: "2rem"}}>
                        <div><span>gem +/-</span></div>
                    </th>
                    <th className="rotated" style={{width: "2rem"}}>
                        <div><span>poäng</span></div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {group.players.map(playerStanding => {
                    const gameDiff = playerStanding.wonGames - playerStanding.lostGames;
                    const setDiff = playerStanding.wonSets - playerStanding.lostSets;
                    return <tr key={playerStanding.player.id}>
                        <td>{playerStanding.position}</td>
                        <td style={{textAlign: "left"}}>{playerStanding.player.firstName} {playerStanding.player.lastName}</td>
                        {playerStanding.meetings.map(meeting => {
                            const resultColor = meeting.points !== undefined &&
                            meeting.points <= 0 && meeting.resultString==='WO'? "var(--buttonDisabled)" : "var(--panelText)"
                            return meeting.opponent.id === playerStanding.player.id ?
                                <td key={meeting.opponent.id} style={{backgroundColor: "var(--panelTableLine)"}}></td>
                                :
                                <td title={meeting.resultString} key={meeting.opponent.id}
                                style={{color:resultColor}}>
                                    {meeting.points === undefined ? "" : meeting.points/10}
                                </td>
                        })}
                        <td>{playerStanding.playedMatches}</td>
                        <td>{playerStanding.wonSets}-{playerStanding.lostSets}</td>
                        <td>{setDiff > 0 ? "+" + setDiff : setDiff}</td>
                        <td>{playerStanding.wonGames}-{playerStanding.lostGames}</td>
                        <td>{gameDiff > 0 ? "+" + gameDiff : gameDiff}</td>
                        <td>{playerStanding.points/10}</td>
                    </tr>
                })}
                </tbody>
            </table>

        </div>);
    }

    getMobileGroup(group: GroupStandingsDto) {
        return (<Swipeable key={"sgroup-" + group.groupNr}
                           onSwipedLeft={this.changeColumns}
                           onSwipedRight={this.changeColumns}>
            <div key={"group-" + group.groupNr} style={{
                position: "relative",
                padding: "0.5rem",
                width: "47rem",
                margin: "0 0.5rem 1.5rem 0.5rem",
                backgroundColor: "var(--panelBg)",
                borderRadius: "0.3rem",
                fontSize: "2rem"
            }}>

                <table className="white-bordered" style={{width: "47rem", textAlign: "right", marginTop: "5rem"}}>
                    <thead>
                    <tr style={{fontSize: "1.8rem"}}>
                        <th colSpan={2} style={{textAlign: "left"}}>
                            <span style={{fontWeight: "bold", fontSize: "3rem"}}>Grupp {group.groupNr}</span>
                        </th>
                        {this.state.columnChanged ?
                            [
                                <th key={"spe" + group.groupNr} className="rotated" style={{width: "3rem"}}>
                                    <div><span>spelade</span></div>
                                </th>,
                                <th key={"set" + group.groupNr} className="rotated" style={{width: "6rem"}}>
                                    <div><span style={{bottom: "1rem", left: "0.5rem"}}>set</span></div>
                                </th>,
                                <th key={"se" + group.groupNr} className="rotated" style={{width: "3rem"}}>
                                    <div><span>set +/-</span></div>
                                </th>,
                                <th key={"gem" + group.groupNr} className="rotated" style={{width: "6rem"}}>
                                    <div><span style={{bottom: "1rem", left: "0.5rem"}}>gem</span></div>
                                </th>,
                                <th key={"ge" + group.groupNr} className="rotated" style={{width: "3rem"}}>
                                    <div><span>gem +/-</span></div>
                                </th>
                            ]
                            :
                            group.players.map(playerStanding => {
                                return (<th key={playerStanding.player.id} className="rotated" style={{width: "3rem"}}>
                                    <div><span>{playerStanding.position} {playerStanding.player.firstName}</span></div>
                                </th>)
                            })}

                        <th className="rotated" style={{width: "3rem"}}>
                            <div><span style={{bottom: "0.8rem"}}>poäng</span></div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {group.players.map(playerStanding => {
                        const gameDiff = playerStanding.wonGames - playerStanding.lostGames;
                        const setDiff = playerStanding.wonSets - playerStanding.lostSets;
                        return <tr key={playerStanding.player.id}>
                            <td style={{width: "1rem"}}>{playerStanding.position}</td>
                            <td style={{textAlign: "left"}}>{playerStanding.player.firstName} {playerStanding.player.lastName}</td>

                            {this.state.columnChanged ?
                                [
                                    <td key={"spe" + group.groupNr}>{playerStanding.playedMatches}</td>,
                                    <td key={"set" + group.groupNr}>{playerStanding.wonSets}-{playerStanding.lostSets}</td>,
                                    <td key={"se" + group.groupNr}>{setDiff === 0 ? "0" : (setDiff > 0 ? "+" + setDiff : setDiff)}</td>,
                                    <td key={"gem" + group.groupNr}>{playerStanding.wonGames}-{playerStanding.lostGames}</td>,
                                    <td key={"ge" + group.groupNr}>{gameDiff === 0 ? "0" : (gameDiff > 0 ? "+" + gameDiff : gameDiff)}</td>
                                ]
                                :
                                playerStanding.meetings.map(meeting => {
                                    return meeting.opponent.id === playerStanding.player.id ?
                                        <td key={meeting.opponent.id} style={{backgroundColor: "var(--panelTableLine)"}}></td>
                                        :
                                        <td key={meeting.opponent.id}>{meeting.points === undefined ? "" : meeting.points/10}</td>
                                })}
                            <td>{playerStanding.points/10}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </Swipeable>);
    }

    changeColumns() {
        const {columnChanged} = this.state;
        this.setState({columnChanged: !columnChanged});
    }
}

import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../BasePage"
import TextInput from "../../components/TextInput"
import {getAccountData, updateAccountData} from "../../api/api"
import {AccountDto, ErrorType, UpdateAccountDataDto} from "../../api/dtos"
import {ErrorMessage} from "../../components/ErrorMessage"
import {mobile} from "../../App"
import {LinkButton} from "../../components/LinkButton"

interface State {
    errorMessage?: string
    redirectTo?: string
    fatalError: boolean
    oldPassword: string
    password1: string
    password2: string
    passwordsInvalid: boolean
    oldPasswordInvalid: boolean
    accountData?: AccountDto
    loading: boolean
}

interface ChangePasswordPageProps {
    redirectTo: string
}

type Props = BasePageProps & ChangePasswordPageProps & RouteComponentProps<{ groupplayId: string }>

class ChangePasswordPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            oldPassword: '',
            oldPasswordInvalid: false,
            password1: '',
            password2: '',
            fatalError: false,
            passwordsInvalid: false,
            loading: true
        };
    }

    async componentDidMount() {
        if (this.props.session) {
            try {
                const accountData = await getAccountData(this.props.session.sessionId);
                this.setState({accountData: accountData});
            } catch (error:any) {
                console.log('error:' + error.type);
                switch (error.type) {
                    case ErrorType.INVALID_SESSION:
                        this.setState({redirectTo: this.props.redirectTo});
                        break;
                    default:
                        this.setState({errorMessage: "", fatalError: true});
                        break;
                }
            }
        } else {
            //no session, redirect away
            this.setState({redirectTo: this.props.redirectTo});
        }
        this.setState({loading: false});
    }

    private handleOldPasswordChange = (currentValue: string) => {
        this.setState({
            oldPassword: currentValue,
            oldPasswordInvalid: false,
            errorMessage: ''
        });
    }

    handlePassword1Change = (currentValue: string) => {
        this.setState({
            password1: currentValue,
            passwordsInvalid: this.state.password2 !== currentValue || (currentValue.length < 4 && currentValue.length > 0)
        });
    }

    handlePassword2Change = (currentValue: string) => {
        this.setState({
            password2: currentValue,
            passwordsInvalid: this.state.password1 !== currentValue || (currentValue.length < 4 && currentValue.length > 0)
        });
    }

    render() {
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        const textMargin = mobile ? "1.5rem" : "0.5rem";
        return (<BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError} sessionRequired={true}>
                <div style={{margin: "auto", display: "flex", flexDirection: "column"}}>
                    <TextInput onChange={this.handleOldPasswordChange} placeHolder={"Nuvarande lösenord"}
                               invalid={this.state.oldPasswordInvalid} password={true}/>
                    <TextInput onChange={this.handlePassword1Change} placeHolder={"Nytt lösenord"}
                               invalid={this.state.passwordsInvalid} password={true}/>
                    <TextInput onChange={this.handlePassword2Change} placeHolder={"Nytt lösenord igen"}
                               invalid={this.state.passwordsInvalid} password={true}/>
                    <ErrorMessage text={this.state.errorMessage}/>
                    <button onClick={this.handleSaveClick} disabled={this.saveDisabled()}>Spara</button>

                    <LinkButton style={{margin: textMargin, textAlign: "right"}} onClick={this.goBack}/>
                </div>
            </BasePage>
        );
    }

    private saveDisabled = (): boolean => {
        if (this.state.accountData) {
            return this.state.oldPassword.length === 0
                ||
                this.state.passwordsInvalid
                ||
                (this.state.oldPassword.length > 0 && this.state.password1.length === 0)
                ||
                (this.state.oldPassword.length === 0 && this.state.password1.length > 0)
        }
        return true;
    }

    goBack = () => {
        this.setState({redirectTo: this.props.redirectTo});
    }

     handleSaveClick = async () => {
        if (this.props.session && this.state.accountData) {
            const updateAccountDataDto: UpdateAccountDataDto = {
                oldPassword: this.state.oldPassword.length > 0 ? this.state.oldPassword : undefined,
                newPassword: this.state.oldPassword.length > 0 ? this.state.password1 : undefined,
            };
            try {
                await updateAccountData(updateAccountDataDto, this.props.session.sessionId);
            } catch (error:any) {
                console.log('error type:' + error.type);
                switch (error.type) {
                    case ErrorType.WRONG_OLD_PASSWORD:
                        this.setState({errorMessage: "Fel nuvarande lösenord", oldPasswordInvalid: true});
                        break;
                    default:
                        this.setState({errorMessage: "", fatalError: true});
                        break;
                }
                return;
            }
            this.setState({redirectTo: this.props.redirectTo});
        } else {
            this.setState({errorMessage: "", fatalError: true});
        }
    }

}

export default ChangePasswordPage;
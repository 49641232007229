import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../BasePage"
import PhoneInput from "../../components/PhoneInput"
import {getAccountData, updateAccountData} from "../../api/api"
import {AccountDto, ErrorType, UpdateAccountDataDto} from "../../api/dtos"
import {ErrorMessage} from "../../components/ErrorMessage"
import {mobile} from "../../App"
import {LinkButton} from "../../components/LinkButton"
import Panel from "../../components/Panel"
import Checkbox from "../../components/Checkbox"
import NumberInputWithLabel from "../../components/NumberInputWithLabel"
import ClipboardUtil from "../../util/ClipboardUtil";
import EmailInput from "../../components/EmailInput";

interface State {
    errorMessage?: string
    redirectTo?: string
    fatalError: boolean
    phone: string
    phoneValid: boolean
    ccEmail: string
    originalCcEmail: string
    ccEmailValid: boolean
    accountData?: AccountDto
    resultMailEnabled: boolean
    mailReminderEnabled: boolean
    mailReminderHours: number
    mailReminderDays: number
    loading: boolean
    email: string
}

interface EditPersonalPrefsPageProps {
    redirectTo: string
}

type Props = BasePageProps & EditPersonalPrefsPageProps & RouteComponentProps<{ groupplayId: string }>

class EditPersonalPrefsPage extends React.Component<Props, State> {

    private static calendarUrlPrefix = "https://gruppspelet.se/api/calendar/";

    constructor(props: Props) {
        super(props)
        this.state = {
            phone: '',
            fatalError: false,
            phoneValid: true,
            ccEmailValid: true,
            resultMailEnabled: true,
            mailReminderEnabled: true,
            mailReminderDays: 0,
            mailReminderHours: 0,
            loading: true,
            ccEmail: '',
            originalCcEmail: '',
            email: ''
        }
        this.handleSaveClick = this.handleSaveClick.bind(this)
        this.handlePhoneChange = this.handlePhoneChange.bind(this)
        this.handleCcEmailChange = this.handleCcEmailChange.bind(this)
        this.goBack = this.goBack.bind(this)
    }

    async componentDidMount() {
        if (this.props.session) {
            try {
                const accountData = await getAccountData(this.props.session.sessionId)
                const dtoMailReminderEnabled = accountData.reminderMailHoursBeforeMatch !== -1
                const dtoMailReminderDays = dtoMailReminderEnabled ? Math.trunc(accountData.reminderMailHoursBeforeMatch / 24) : 3
                const dtoMailReminderHours = dtoMailReminderEnabled ? accountData.reminderMailHoursBeforeMatch - 24 * dtoMailReminderDays : 0
                this.setState({
                    accountData: accountData,
                    phone: accountData.phone,
                    mailReminderEnabled: dtoMailReminderEnabled,
                    mailReminderHours: dtoMailReminderHours,
                    mailReminderDays: dtoMailReminderDays,
                    resultMailEnabled: accountData.resultMail,
                    email: accountData.email,
                    ccEmail: accountData.ccEmail === undefined ? '' : accountData.ccEmail,
                    originalCcEmail: accountData.ccEmail === undefined ? '' : accountData.ccEmail
                })
            } catch (error: any) {
                console.log('error:' + error.type)
                switch (error.type) {
                    case ErrorType.INVALID_SESSION:
                        this.setState({redirectTo: this.props.redirectTo})
                        break
                    default:
                        this.setState({errorMessage: "", fatalError: true})
                        break
                }
            }
        } else {
            //no session, redirect away
            this.setState({redirectTo: this.props.redirectTo})
        }
        this.setState({loading: false})
    }

    handlePhoneChange(currentValue: string, valid: boolean) {
        this.setState({phone: currentValue, phoneValid: valid})
    }

    handleCcEmailChange(currentValue: string, valid: boolean) {
        this.setState({ccEmail: currentValue, ccEmailValid: valid})
    }

    render() {
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>)
        }
        const {resultMailEnabled, mailReminderEnabled, mailReminderHours, mailReminderDays, phone, errorMessage, ccEmail} = this.state
        const textMargin = mobile ? "1.5rem" : "0.5rem"
        return (<BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}
                          sessionRequired={true}>
                <div style={{margin: "auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <ErrorMessage text={errorMessage}/>
                    <Panel>
                        <span style={{fontWeight:"bold"}}>{this.state.email}</span><br/>
                        <span>Din primära mail som du loggar in med kan du ej ändra själv. För att uppdatera den, maila till info@gruppspelet.se!</span>
                    </Panel>
                    <Panel center={true}>
                        <span style={{margin: textMargin}}>cc mail (kopia på alla mail från gruppspelet.se):</span>
                        <EmailInput onChange={this.handleCcEmailChange} value={ccEmail} emptyIsValid={true} placeholder="CC Email" />
                    </Panel>
                    <Panel center={true}>
                        <span style={{margin: textMargin}}>Telefon:</span>
                        <PhoneInput onChange={this.handlePhoneChange} value={phone} allowEmpty={false}
                                    placeHolder={"Telefon"}/>
                    </Panel>
                    <Panel center={true}>
                    <div style={{margin:"0 auto 2rem auto",width:"fit-content"}}>
                            <Checkbox checked={resultMailEnabled} onChange={this.resultMailEnabledCheckboxClicked}
                                      text="Maila mig när resultat registreras i min grupp"
                                      id={"resultMailEnabled"}/>
                            <Checkbox checked={mailReminderEnabled} onChange={this.mailReminderEnabledCheckboxClicked}
                                      text="Maila mig påminnelser om matcher"
                                      id={"mailReminderEnabled"}/>
                        </div>
                        <span style={{
                            textAlign: "center",
                            color: mailReminderEnabled ? "inherit" : "var(--buttonDisabled)"
                        }}>
                            Hur lång tid innan matchen skall det mailas?
                        </span>
                        <NumberInputWithLabel label={"Dygn:"} onChange={this.mailReminderDaysChanged}
                                              disabled={!mailReminderEnabled}
                                              value={mailReminderDays} min={0} max={7}/>
                        <NumberInputWithLabel label={"Timmar:"} onChange={this.mailReminderHoursChanged}
                                              disabled={!mailReminderEnabled}
                                              value={mailReminderHours} min={0} max={23}/>
                    </Panel>
                    <Panel center={true}>
                        <div style={{marginBottom: "20px"}}>
                            Synkronisera med din kalender: (
                            <a target="_blank" rel="noreferrer noopener"
                               href="https://www.google.com/search?q=subscribe+to+online+calendar">Hur?</a>)
                        </div>

                        <div style={{cursor: "pointer", color: "var(--buttonBg)"}}
                             onClick={() => this.copyCalendarUrlToClipboard()}>{EditPersonalPrefsPage.calendarUrlPrefix}{this.props.session?.accountUid}</div>
                    </Panel>
                    <button style={{padding: "0 1rem"}} onClick={this.handleSaveClick}
                            disabled={this.saveDisabled()}>Spara
                    </button>
                    <LinkButton style={{ margin: textMargin, textAlign: "right"}}
                                onClick={this.goBack}/>
                </div>
            </BasePage>
        )
    }

    copyCalendarUrlToClipboard(): any {
        ClipboardUtil.copyToClipboard(EditPersonalPrefsPage.calendarUrlPrefix + this.props.session?.accountUid);
        alert('Kalenderadressen är kopierad så du kan paste:a in den i din kalender!');
    }

    saveDisabled(): boolean {
        if (this.state.accountData) {
            if(!this.state.phoneValid) {
                return true;
            }
            if(!this.state.ccEmailValid) {
                return true;
            }
            if(this.state.phone !== this.state.accountData.phone) {
                return false;
            }
            if(this.state.ccEmail !== this.state.accountData.ccEmail) {
                return false;
            }
            if(this.state.resultMailEnabled !== this.state.accountData.resultMail) {
                return false;
            }
            if(this.state.mailReminderEnabled === false && this.state.accountData.reminderMailHoursBeforeMatch === -1) {
                return true;
            }
            if(this.state.mailReminderEnabled === true &&
                (this.state.mailReminderHours === (this.state.accountData.reminderMailHoursBeforeMatch - 24 * this.state.mailReminderDays)) &&
                (this.state.mailReminderDays === (Math.trunc(this.state.accountData.reminderMailHoursBeforeMatch / 24)))) {
                return true;
            }
        }
        return false
    }

    goBack() {
        this.setState({redirectTo: this.props.redirectTo})
    }

    private mailReminderEnabledCheckboxClicked = (checked: boolean) => {
        this.setState({mailReminderEnabled: checked})
    }

    private resultMailEnabledCheckboxClicked = (checked: boolean) => {
        this.setState({resultMailEnabled: checked})
    }

    private mailReminderDaysChanged = (value: number) => {
        this.setState({mailReminderDays: value})
    }

    private mailReminderHoursChanged = (value: number) => {
        this.setState({mailReminderHours: value})
    }

    async handleSaveClick() {
        if (this.props.session && this.state.accountData) {

            const mailreminderHours = this.state.mailReminderEnabled ?
                this.state.mailReminderDays * 24 + this.state.mailReminderHours : -1

            console.log("ccEmail:'" + this.state.ccEmail + "', orig:'" + this.state.originalCcEmail + "'")

            const updateAccountDataDto: UpdateAccountDataDto = {
                phone: this.state.accountData.phone === this.state.phone ? undefined : this.state.phone,
                ccEmail: this.state.originalCcEmail === this.state.ccEmail ? undefined : this.state.ccEmail,
                resultMail: this.state.accountData.resultMail === this.state.resultMailEnabled ? undefined : this.state.resultMailEnabled,
                reminderMailHoursBeforeMatch: this.state.accountData.reminderMailHoursBeforeMatch === mailreminderHours ? undefined : mailreminderHours
            }
            try {
                await updateAccountData(updateAccountDataDto, this.props.session.sessionId)
            } catch (error: any) {
                console.log('error type:' + error.type)
                this.setState({errorMessage: "", fatalError: true})
                return
            }
            this.setState({redirectTo: this.props.redirectTo})
        } else {
            this.setState({errorMessage: "", fatalError: true})
        }
    }
}

export default EditPersonalPrefsPage

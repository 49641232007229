import * as React from "react"
import {mobile} from "../App"
import NumberInput from "./NumberInput"

interface Props {
    label: string
    onChange: (value: number) => void
    value: number
    min: number
    max: number
    disabled?: boolean
    step?: number
}

export default class NumberInputWithLabel extends React.Component<Props> {

    render() {
        return (
            <div style={{
                display: "flex",
                alignItems: "center",
                width: mobile ? "46rem" : "21rem",
                margin: "auto",
                marginTop: mobile ? "2rem" : 0
            }}>
                        <span style={{
                            width: mobile ? "20rem" : "8rem",
                            textAlign: "end",
                            marginRight: "1rem",
                        }}>{this.props.label}</span>
                <NumberInput onChange={this.props.onChange} value={this.props.value}
                             min={this.props.min} max={this.props.max} disabled={this.props.disabled}
                             step={this.props.step}/>
            </div>

        )
    }
}
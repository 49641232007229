import * as React from "react"
import {mobile} from "../App"

interface Props {
    onChange: (currentValue: string, valid: boolean) => void
    autoFocus?: boolean
    value?: string
}

interface State {
    valid: boolean
    value: string
}

export default class DateInput extends React.Component<Props, State> {

    DATE_REGEXP = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

    constructor(props: Props) {
        super(props);
        const phoneValue = this.props.value ? this.props.value : '';
        this.state = {
            value: phoneValue,
            valid: this.validate(phoneValue)
        };
    }

    render() {
        const mobileStyle = {
            width: "23rem",
            border: this.state.valid ? "0.5rem solid var(--buttonBg)" : "0.5rem solid red"
        };
        const deskStyle = {
            width: "5.7rem",
            border: this.state.valid ? "0.3rem solid var(--buttonBg)" : "0.3rem solid red"
        };
        const style = mobile ? mobileStyle : deskStyle;
        return (
            <input type="text" placeholder={"åååå-mm-dd"} onChange={this.onChange}
                   value={this.state.value}
                   style={style}
                   name={"åååå-mm-dd"} autoFocus={this.props.autoFocus} />
        );
    }

    private onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        const valid = this.validate(value);
        this.setState(
            {
                value: value,
                valid: valid
            });
        this.props.onChange(value, valid);
    };

    private validate = (value: string): boolean => {
        const date : Date = new Date(value);
        return this.DATE_REGEXP.test(value) && !isNaN(date.getDay());
    }
}